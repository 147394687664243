@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
body#text {
  margin: 0;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, label, button.ui, h1, h2, h3, h4, h5, p {
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  padding-top: 85px;
}

html {
  font-size: 16px;
}